import React,{useState,useEffect,useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import SuccessCheck from "../../assets/images/check_fill.svg"
import Fail from "../../assets/images/cross.svg"
import AxiosInstance from '../../config/AxiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Container, Typography, Box, Grid, Stack, List, ListItem,
    ListItemIcon, ListItemText, Autocomplete, TextField,
    Button, FormControl, Divider
} from '@mui/material'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import DurationIcon from '../../assets/images/duration.png'
import BoxIcon from '../../assets/images/box.png';
import PadlockIcon from '../../assets/images/padlock.png';
import ProtecionIcon from '../../assets/images/protection.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from '@emotion/styled';
import { StyledTextfield } from '../../components/styled-components/inputField';
import { StyledButton } from '../../components/styled-components/button';
import { toast } from 'react-toastify';

function RealTimePrice({isRedirected, label}) {
  const [promoError, setPromoError] = useState(""); // promo code error state
  const [validPromo, setValidPromo] = useState([]); // valid promo code state
  const [loading, setLoading] = useState(true); // loading state

  const promoAdornmentIcon = promoError === "Invalid Promo code" ? Fail : (promoError === "Valid Promo code" ? SuccessCheck : null); // icon for the promo code validation
  const promoVariant = promoError === "Invalid Promo code" ? 'error' : (promoError === "Valid Promo code" ? 'success' : null); // styling for promo code validatin message

  const [resDat, setResDat] = useState({}) // response data state
  const effectExecutedRef = useRef(false); // value to keep track of the useEffect execution
  const effExecutedRef = useRef(false); // value to keep track of the useEffect execution
  const navigate = useNavigate(); // function to navigate to desired page of the app


  const ch = JSON.parse(sessionStorage.getItem('bigboxinfo')).charges.map(entry => entry); // list of charges object obtained from the session storage
  const pv = JSON.parse(sessionStorage.getItem('bigboxinfo')).discounts.map(entry => entry); // list of discount object obtained from the session storage
  const dr = JSON.parse(sessionStorage.getItem('bigboxinfo')).delivery_rates.map(entry => entry); // list of delivery rates object obtained from the session storage
  const regDat = JSON.parse(sessionStorage.getItem('registration_data'))
  const zc = sessionStorage.getItem('delcalzip') || ''; // zip code for the delivery rate calculation
  const rtpd = JSON.parse(sessionStorage.getItem('realtime_price_data')) || {} // variable to restoring of data enterd by user in this page, if the user chooses to come back here from the next page
  const s3dat = JSON.parse(sessionStorage.getItem('s3data')) || {}

  // function to find the amount of some items using the id
  const findAmountById = (id) => {
    // extract the single object from the charges list if its id equals to the given id
    const charge = ch.find(charge => charge.id === id);
    // returns the amount of the extracted object based on the id
    return charge ? charge.amount : null;
};
  // function to find the delivery rates using the zip code
  const findAmountByZip = (zip) =>{
    // works similar to the function explained above
    const deliveryRate = dr.find(deliveryrate => deliveryrate.zip === zip)
    return deliveryRate ? deliveryRate.amount : null
  }

  const defaultProtection = rtpd.protection !== undefined ? rtpd.protection : true; // default protection choice(taken from rtpd variable if the user comes back from next page or true)
  const defaultProtectionValue = rtpd.protection !== undefined ? (rtpd.protection ? findAmountById('27') : '0.00') : findAmountById('27'); // default protection value(assigned in the same way explained above)
  const [promocode, setPromoCode] = useState(rtpd.promotion_code || ''); // promo code state
  const [protection, setProtection] = useState(defaultProtection); // protection state
  const [protectionValue, setProtectionValue] = useState(defaultProtectionValue); // protection value state
  const [discount, setDiscount] = useState(""); // discount state
  const [focus, setFocus] = useState(false); // focus state
  const [disabled, setDisabled] = useState(false); // disabled state

  // function to find the value of the applied promotion code
  const findPromotionvalueByCode = (code) => {
    // works similar to the other find functions
    const discount = pv.find(discount => discount.promotion_code === code);
    return discount ? discount.amount:null;
  }

  // function to calculate the padlock price based on its quantity
  const calculateMerchandiseValue = (quantity, lock) => {
    if (quantity === undefined) {
        return '0.00';
    }
    const pricePerItem = Number(findAmountById("9"));
    if (lock) {
        return (pricePerItem*quantity).toFixed(2).toString();
    }
    else{
        return (pricePerItem).toFixed(2).toString();
    }
  };

  // sendData object state
  const [sendData, setSendData] = useState({
    zip_code:zc,
    delivery_date:"",
    number_of_boxes:rtpd.number_of_boxes || "1",
    number_of_months_needed:rtpd.number_of_months_needed || "1",
    promotion_code:rtpd.promotion_code || '',
    promotion_code_value:rtpd.promotion_code_value || '0.00',
    rental_rate:findAmountById("2"),
    padlock_quantity:rtpd.number_of_padlocks || "0",
    padlock_price:calculateMerchandiseValue(rtpd.number_of_padlocks, false) || calculateMerchandiseValue(0, false)
   });

  // useEffect to update the padlock pricing in the sendData object
  useEffect(() => {
    if (effExecutedRef.current) return;
    effExecutedRef.current = true;
    const numberOfPadlocks = rtpd.number_of_padlocks;
    const validPadlocks = numberOfPadlocks && !isNaN(Number(numberOfPadlocks))
      ? Number(numberOfPadlocks)
      : 0;

    setSendData(prevData => ({
      ...prevData,
      padlock_price: calculateMerchandiseValue(validPadlocks, false)
    }));
    // eslint-disable-next-line
  }, [rtpd]);

  // useEffect to set the list of valid promo codes form the bigboxinfo stored in the previous step
  useEffect(()=>{
    if (effectExecutedRef.current) return;
    effectExecutedRef.current = true;
    window.scrollTo(0, 0);
    const getPromo = async ()=>{
      const cachedData = JSON.parse(sessionStorage.getItem('bigboxinfo'));
      const promocodes = cachedData.discounts.map(entry => entry.promotion_code);
      setValidPromo(promocodes);
    };
    getPromo();
  },[])


  // useEffect to call the calculation api each time the value changes
  useEffect(()=>{
    const getCalculation = async (df)=>{
        // preparing the data for calculation api call
        // some properties are set here rather than setting it directly in the sendData object
        const calcDat = {
            ...sendData,
            delivery_fee:(Number(df).toFixed(2)).toString(),
            protection_plan_quantity:protection?sendData.number_of_boxes: "0",
            protection_plan_price:protection?Number(protectionValue).toFixed(2):"0.00"
        }
      try{
        setLoading(true); // loading is true before the api call (this will show the loading animation in the place of the values before calculation)
        const response = await AxiosInstance.post("/bigbox-calculate-cost",calcDat);
        setResDat(response.data.data)
        setDisabled(false);
      }
      catch(error){
        setDisabled(true);
        toast.error('Error: We are unable to provide quote now. Please call us for a quote.')
      }finally{
        setLoading(false);// loading is set to false to stop showing the loading animation
      }
    };
    const deliveryFee = findAmountByZip(zc)
    setDiscount(sendData.number_of_months_needed === "3" ? "99.00": "0.00");
    getCalculation(deliveryFee);
    // eslint-disable-next-line
  },[sendData,protection,protectionValue])



  // function to remove the promocode (triggered by clicking on the remove button in the UI near to the promocode field)
  const removePromoCode = () => {
    setSendData((prevState) => ({
      ...prevState,
      promotion_code: '',
      promotion_code_value: '0.00'
    }));
    setPromoCode('');
    setPromoError('');
  };

  // useEffect to set the protection value whenever the protection choice changes
  useEffect(()=>{
     const getProtectionValue = () => {
       setProtectionValue(protection ? findAmountById('27') : '0.00');
     };
     getProtectionValue();
     // eslint-disable-next-line
  },[protection])

  // function to set the promotion code value based on the promocode entered
  const applyPromoCode = (promo) => {
    // if the promocode entered in the field is present in the list of valid promo codes,
    // then it is a valid promo code and the promocode value is set with the value returned from the findPromotionvalueByCode function
    // else the promo code entered is invalid and the promotion code value is set to 0.00
    const promoUppercase = promo.toUpperCase(); // converts to uppercase to compare with the list of valid promo codes
    if (validPromo.includes(promoUppercase)) {
      setPromoError('Valid Promo code');
      const promoValue = findPromotionvalueByCode(promoUppercase);
      setSendData((prevState) => ({
        ...prevState,
        promotion_code_value: promoValue
      }));
    } else {
      setPromoError("Invalid Promo code");
      setSendData((prevState) => ({
        ...prevState,
        promotion_code_value: '0.00'
      }));
    }
  };

  const createQuote = async () =>{
    const quoteDat = {
        lead_id: sessionStorage.getItem('lead_id'),
        quote_id: sessionStorage.getItem('quote_id') || '0',
        userid: '10001',
        protection_plan: protection? "1": "0",
        zip_code: zc,
        delivery_date:s3dat.delivery_date || '',
        number_of_boxes:sendData.number_of_boxes,
        number_of_months_needed:sendData.number_of_months_needed,
        delivery_time: s3dat.delivery_time || '',
        storage_location: s3dat.location || 'My Location',
        pickup_date: s3dat.pickup_date || '',
        referral_source: 'bb',
        rental_rate: Number(Number(resDat.cost.monthly/sendData.number_of_boxes).toFixed(2)),
        delivery_fee: Number(findAmountByZip(zc)).toFixed(2),
        delivery_discount: Number(discount).toFixed(2),
        promotion_code: sendData.promotion_code,
        promotion_code_value: sendData.promotion_code_value,
        one_time_fee: Number(Number(resDat.cost.oneTimeFee).toFixed(2)),
        merchandise_fee: Number(Number(resDat.cost.merchandise).toFixed(2)),
        padlock_quantity: sendData.padlock_quantity,
        padlock_price: sendData.padlock_quantity === "0" ? "0.00":sendData.padlock_price,
        protection_plan_quantity: protection?sendData.number_of_boxes: "0",
        protection_plan_price: protection?(Number(protectionValue)).toFixed(2):"0.00",
        tax: Number(Number(resDat.cost.tax).toFixed(2)),
        total: Number(Number(resDat.cost.final_total).toFixed(2)),
        customer:{
            first:regDat.first_name,
            last: regDat.last_name,
            phone_number: regDat.phone_number,
            phone:{
                area: regDat.phone_number.slice(0,3),
                prefix: regDat.phone_number.slice(3,6),
                number: regDat.phone_number.slice(6)
            },
            email: regDat.email,
            address: s3dat.address1 || '',
            address2: s3dat.address2 || '',
            city: s3dat.city || '',
            state: s3dat.state || '',
            zip: zc
        }
    }
    try{
        sessionStorage.setItem('quoteData', JSON.stringify(quoteDat))
    const response = await AxiosInstance.post("/bigbox-create-quote",quoteDat);
    sessionStorage.setItem('quote_id', response.data.data.bigbox_quote_id);
    sessionStorage.setItem('prospect_id', response.data.data.bigbox_prospect_id);
    return true
}
    catch(err){
        toast.error(err);
        return true
    }
  }


  // function triggered when the user clicks on the "continue" button
 // const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    try {
      let passed = await createQuote();
      if (passed) {
        sessionStorage.setItem('realtime_price_data', JSON.stringify({
          number_of_boxes: sendData.number_of_boxes,
          number_of_months_needed: sendData.number_of_months_needed,
          promotion_code: sendData.promotion_code,
          promotion_code_value: sendData.promotion_code_value,
          number_of_padlocks: sendData.padlock_quantity,
          protection: protection,
        }));

        sessionStorage.setItem('quote_details', JSON.stringify({
          monthly_rent: resDat.cost?.monthly || '',
          mon_prot_plan: protectionValue,
          lock: sendData.padlock_price,
          in_de_fe: resDat.cost?.delivery || "",
          in_de_di: discount,
          promo_disc: sendData.promotion_code_value,
          subTot: resDat.cost?.total || "",
          saleTax: resDat.cost?.tax || "",
          total: resDat.cost?.final_total || "",
        }));

        sessionStorage.setItem('calcData', JSON.stringify({
          ...sendData,
          protection_plan_quantity: protection ? sendData.number_of_boxes : "0",
          protection_plan_price: protection ? Number(protectionValue).toFixed(2) : "0.00",
        }));

        sessionStorage.setItem('calculation_response', JSON.stringify(resDat));

        navigate(`/rent-online`);
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // function to go to previous page (triggered when the "Back" button is clicked)
  const handleBack = () => {
    // removes the data filled in this page(if present) before navigating to the previous page
    // so that when the user comes back again from the previous step the data will not be restored
    if (sessionStorage.getItem("realtime_price_data") !== null) {
        sessionStorage.removeItem('realtime_price_data');
    }
    navigate(`/`)
  };

  // function to handle the change in the number of months needed value
  const handleForm2Change = (input) => (event, value) => {
    if (input === 'number_of_months_needed') {
      setSendData((prevState) => ({
        ...prevState,
        [input]: value ? value.value : '',
      }));
    }
  };

  // function to handle the increased change in the number of boxes
  const handleBoxIncrement = () => {
    setSendData((prevState) => ({
      ...prevState,
      number_of_boxes: (Number(prevState.number_of_boxes) + 1).toString(),
    }));
  };

  // function to handle the decreased change in the number of boxes
  const handleBoxDecrement = () => {
    setSendData((prevState) => ({
      ...prevState,
      number_of_boxes: (Number(prevState.number_of_boxes) - 1).toString(),
    }));
  };

  // function to handle the increased change in the number of padlocks
  const handleLockIncrement = () => {
    setSendData((prevState) => {
        const newQuantity = (Number(prevState.padlock_quantity) + 1).toString();
        return {
            ...prevState,
            padlock_quantity: newQuantity,
            padlock_price: calculateMerchandiseValue(Number(newQuantity), false)
        };
    });
  };

  // function to handle the decreased change in the number of padlocks
  const handleLockDecrement = () => {
    setSendData((prevState) => {
        const newQuantity = (Number(prevState.padlock_quantity) - 1).toString();
        return {
            ...prevState,
            padlock_quantity: newQuantity,
            padlock_price: calculateMerchandiseValue(Number(newQuantity), false)
        };
    });
  };

  // function to handle the change in the protection choice change
  const handleProtectionChange = (event) => {
    const value = event.target.value === 'yes';
    setProtection(value);
    setProtectionValue(value ? findAmountById('27') : '0.00');
  };

  // list of objects that holds up the label and the value for the "number of months needed" dropdown
  const month = [
    { label: 'Month to Month', value: '1' },
    { label: 'Three Months +', value: '3' },
  ];

  // Define the options for the dropdown
  const protectionOptions = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
    ];

  // styling function for the dropdown
  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
        return {
            '& .MuiInputBase-root': {
                fontSize: '14px',
                borderRadius: '8px',
                '& .MuiInputBase-input': {
                    padding: 0
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.primary.main}`,
                },
                '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: theme.palette.background.disabled,
                        borderColor: 'transparent'
                    }
                }
            }
        }
  });

  // styling function for the info
  const StyledInfo = styled(Typography)(({ theme }) => {
        return {
            backgroundColor: theme.palette.background.grey2,
            color: '#6B6B6B',
            borderRadius: '6px',
            padding: '8px 10px',
            display: 'inline-block',
            marginBlock: '12px',
            '@media screen and (max-width: 600px)': {
                textAlign: 'center'
            }
        }
  });

  // styling function for the count increase or decrease button
  const StyledCountButton = styled(Button)(({ theme }) => {
        return {
            padding: 1,
            backgroundColor: '#EAF5FF',
            border: '1px solid #014D97',
            color: theme.palette.primary.main,
            borderRadius: '8px',
            '&.Mui-disabled': {
                backgroundColor: theme.palette.background.disabled,
                color: '#CECECE',
                borderColor: '#CECECE'
            }
        }
  });

  // styling function for the quote detais
  const StyledCostCard = styled(Box)(({ theme }) => {
        return {
            backgroundColor: '#EAF5FF',
            border: '1px solid #77BCFF',
            paddingBlock: '24px',
            paddingInline: '16px',
            borderRadius: '8px',
            '@media screen and (max-width:600px)': {

            }
        }
  });

  // styling function for the monthly quote details
  const StyledMonthlyCostCard = styled(Box)(({ theme }) => {
        return {
            backgroundColor: '#FAFDFF',
            border: '1px solid #77BCFF',
            paddingBlock: '24px',
            paddingInline: '16px',
            borderRadius: '8px',
            '@media screen and (max-width:600px)': {

            }
        }
  });

  // styling function for the outlined button
  const StyledOutlinedButton = styled(Button)(({ theme }) => {
        return {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px',
            fontFamily: `'program', sans- serif`,
            fontWeight: 700,
            color: theme.palette.secondary.main,
            textTransform: 'capitalize'
        }
  });

  const DeliveryFeeDisplay = ({ loading, deliveryFee }) => {
    return (
      <>
        ${loading ? (
          <CircularProgress size={10} color='inherit' />
        ) : (
          <>
            {deliveryFee !== undefined && deliveryFee !== null ? deliveryFee : ""}
          </>
        )} Delivery Fee
      </>
    );
    }

  return (
        <>
            <Box py={{ xs: 5, md: 10 }}>
                <Container fixed>
                    <Typography mb={{ xs: 2, md: 4 }} color={'primary.main'} variant='h2'>{label.headerp1}
                        <Typography color={'secondary.main'} variant='h2' component={'span'}>{label.headerp2}</Typography></Typography>
                    <Grid container spacing={2}>
                        {/* Left side grid */}
                        <Grid item xs={12} md={8} >
                            {/* Question-1 */}
                            <Box my={{ xs: 1.5, sm: 2.5 }} className='product-item' border={'1px solid #DFDFDF'} borderRadius={'8px'} overflow={'hidden'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Stack p={3} bgcolor={'background.grey2'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography component={'img'} src={DurationIcon} alt='Big-Box-storage' sx={{ width: { xs: '100px', sm: '75%' } }}></Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box p={{ xs: 1.75, sm: 2 }}>
                                            <Typography mb={{ xs: 1, sm: 2 }} color={'text.secondary'} variant='question'>1. {label.headers1}</Typography>
                                            <List dense >
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step1bullet1}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step1bullet2}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step1bullet3}
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* Autocomplete */}
                                            <StyledAutocomplete
                                                className='styled-autocomplete'
                                                id="number_of_months_needed"
                                                options={month}
                                                value={month.find(option => option.value === sendData.number_of_months_needed) || null}
                                                onChange={handleForm2Change('number_of_months_needed')}
                                                sx={{ width: { xs: '100%', sm: 250 }, my: '16px' }}
                                                renderInput={(params) => <TextField {...params} />}
                                                disabled={loading}
                                            />
                                            <StyledInfo width={{ xs: '100%', sm: 'auto' }}>
                                                {sendData.number_of_months_needed === "3"?(<>$99 Delivery Discount!</>)
                                                :(<DeliveryFeeDisplay loading={loading} deliveryFee={resDat.cost?.delivery} />)}
                                            </StyledInfo>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>
                            {/* Question-2 */}
                            <Box my={{ xs: 1.5, sm: 2.5 }} className='product-item' border={'1px solid #DFDFDF'} borderRadius={'8px'} overflow={'hidden'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Stack p={3} bgcolor={'background.grey2'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography component={'img'} src={BoxIcon} alt='Big-Box-storage' sx={{ width: { xs: '100px', sm: '75%' } }}></Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box p={{ xs: 1.75, sm: 2 }}>
                                            <Typography mb={{ xs: 1, sm: 2 }} color={'text.secondary'} variant='question'>2. {label.headers2}</Typography>
                                            <List dense>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step2bullet1}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step2bullet2}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step2bullet3}
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* count Button */}
                                            <Box my={2} >
                                                <Stack direction={'row'} gap={2} justifyContent={{ xs: 'center', sm: 'start' }}>
                                                    <StyledCountButton disabled={loading || Number(sendData.number_of_boxes) === 1} onClick={handleBoxDecrement}><RemoveIcon /></StyledCountButton>
                                                    <StyledTextfield sx={{ maxWidth: 60, textAlign: 'center' }} disabled={loading} value={sendData.number_of_boxes} inputProps={{ readOnly: true, style: { textAlign: 'center' } }}/>
                                                    <StyledCountButton onClick={handleBoxIncrement} disabled={loading}><AddIcon /></StyledCountButton>

                                                </Stack>
                                            </Box>
                                        <StyledInfo width={{ xs: '100%', sm: 'auto' }}>${findAmountById("2")} per Box</StyledInfo>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>
                            {/* Question-3 */}
                            <Box my={{ xs: 1.5, sm: 2.5 }} className='product-item' border={'1px solid #DFDFDF'} borderRadius={'8px'} overflow={'hidden'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Stack p={3} bgcolor={'background.grey2'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography component={'img'} src={PadlockIcon} alt='Big-Box-Self-storage' sx={{ width: { xs: '100px', sm: '75%' } }}></Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box p={{ xs: 1.75, sm: 2 }}>
                                            <Typography mb={{ xs: 1, sm: 2 }} color={'text.secondary'} variant='question'>3. {label.headers3}</Typography>
                                            <List dense>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step3bullet1}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step3bullet2}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step3bullet3}
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* count Button */}
                                            <Box my={2}>
                                                <Stack direction={'row'} gap={2} justifyContent={{ xs: 'center', sm: 'start' }}>
                                                    <StyledCountButton disabled={loading || Number(sendData.padlock_quantity)===0} onClick={handleLockDecrement}><RemoveIcon /></StyledCountButton>
                                                    <StyledTextfield sx={{ maxWidth: 60, textAlign: 'center' }} disabled={loading} value={sendData.padlock_quantity} inputProps={{ readOnly: true, style: { textAlign: 'center' } }}/>
                                                    <StyledCountButton disabled={loading || Number(sendData.padlock_quantity)===10} onClick={handleLockIncrement}><AddIcon /></StyledCountButton>


                                                </Stack>
                                            </Box>
                                            <StyledInfo width={{ xs: '100%', sm: 'auto' }}>${findAmountById("9")} per Padlock</StyledInfo>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>
                            {/* Question-4 */}
                            <Box my={{ xs: 1.5, sm: 2.5 }} className='product-item' border={'1px solid #DFDFDF'} borderRadius={'8px'} overflow={'hidden'}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Stack p={3} bgcolor={'background.grey2'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography component={'img'} src={ProtecionIcon} alt='Big-Box-storage' sx={{ width: { xs: '100px', sm: '75%' } }}></Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box p={{ xs: 1.75, sm: 2 }}>
                                            <Typography mb={{ xs: 1, sm: 2 }} color={'text.secondary'} variant='question'>4. {label.headers4}</Typography>
                                            <List dense>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={label.step4bullet1}
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                              {label.step4bullet2}
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem  >
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        <DoneRoundedIcon fontSize='16px' />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                               {label.step4bullet3}
                                                              <a href="https://www.bigbox.com/terms-and-conditions/idv-restrictions" target="_blank" rel="noopener noreferrer">
                                                                {label.link}
                                                              </a>
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                            {/* Radio Button */}
                                            <Box my={1}>
                                                <FormControl>
                                                    <Autocomplete
                                                        id="protection"
                                                        options={protectionOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        disabled={loading}
                                                        value={protectionOptions.find(option => option.value === (protection ? 'yes' : 'no')) || null}
                                                        onChange={(event, newValue) => {
                                                            // Create a synthetic event object to match the expected format of handleProtectionChange
                                                            const syntheticEvent = {
                                                              target: {
                                                                value: newValue ? newValue.value : 'no' // Default to 'no' if no selection
                                                              }
                                                            };
                                                            handleProtectionChange(syntheticEvent);
                                                          }}
                                                        sx={{ width: { xs: '100%', sm: 250 }, my: '16px' }}
                                                        renderInput={(params) => <TextField {...params} label="Select Protection" />}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <StyledInfo width={{ xs: '100%', sm: 'auto' }}>$7.00 per container</StyledInfo>

                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>

                        </Grid>
                        {/* Right Side grid */}
                        <Grid item xs={12} md={4}>
                            {/* Today cost */}
                            <StyledCostCard mt={{ xs: 'auto', sm: 3 }} mb={2}>
                                <Typography variant='h3' color={'text.secondary'}>Today’s Cost</Typography>
                                <Stack direction={'column'} rowGap={1.5}>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography fontSize={'1.25rem'} fontWeight={700} >Monthly Rent:</Typography>
                                        {loading?(
                                            <Typography><CircularProgress size={10}></CircularProgress></Typography>
                                        ):(<Typography fontSize={'1.25rem'} fontWeight={700} >${(resDat.cost?.monthly).toFixed(2) || ''}</Typography>)}
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Monthly Protection Plan:</Typography>
                                        <Typography>${Number(protectionValue*sendData.number_of_boxes).toFixed(2)}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Lock:</Typography>
                                        <Typography>${calculateMerchandiseValue(Number(sendData.padlock_quantity), true)}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Initial Delivery Fee:</Typography>
                                        {loading?(
                                            <Typography><CircularProgress size={10}></CircularProgress></Typography>
                                        ):(<Typography>${
                                            Number(findAmountByZip(zc)).toFixed(2)
                                            //Number(resDat.cost?.delivery) === 0? "99.00": Number(resDat.cost?.delivery).toFixed(2)
                                        //resDat.cost?.delivery !== undefined && resDat.cost?.delivery !== null ? resDat.cost.delivery.toFixed(2) : ""
                                        }</Typography>)}
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Initial Delivery Discount:</Typography>
                                        {loading ? (
                                            <Typography><CircularProgress size={10} /></Typography>
                                        ) : (
                                            <Typography>
                                                (${Number(discount).toFixed(2)})
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Promotion Discount:</Typography>
                                        <Typography color={'success.main'} fontWeight={600}>(${sendData.promotion_code_value})</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography fontWeight={600}>SubTotal</Typography>
                                        {loading?(
                                            <Typography><CircularProgress size={10}></CircularProgress></Typography>
                                        ):(<Typography fontWeight={600}>${((resDat.cost?.final_total ?? 0) - (Number(resDat.cost?.tax ?? 0))).toFixed(2) || ""}</Typography>)}
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Sales Tax</Typography>
                                        {loading?(
                                            <Typography><CircularProgress size={10}></CircularProgress></Typography>
                                        ):(<Typography>${Number(resDat.cost?.tax !== undefined && resDat.cost?.tax !== null ? resDat.cost.tax : "").toFixed(2)}</Typography>)}
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography fontWeight={600}>Total</Typography>
                                        {loading?(
                                            <Typography><CircularProgress size={10}></CircularProgress></Typography>
                                        ):(<Typography fontWeight={600}>${resDat.cost?.final_total.toFixed(2) || ""}</Typography>)}
                                    </Stack>
                                </Stack>
                                <Box my={3}>
                                    <Divider />
                                </Box>
                                <Typography mb={2} fontWeight={600}>Discount Code</Typography>
                                <Stack mb={3} direction={'row'} gap={2} useFlexGap alignItems={'end'}>
                                    <StyledTextfield
                                        sx={{ backgroundColor: '#FFF' }}
                                        id="promotion_code"
                                        placeholder="Enter Promocode"
                                        name='promotion_code'
                                        autoFocus = {focus}
                                        disabled={loading}
                                        onChange={(event) => {setPromoCode(event.target.value); setFocus(true);}}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && promocode) {
                                                setFocus(false);
                                                setSendData({
                                                    ...sendData,
                                                    promotion_code: promocode
                                                });
                                                applyPromoCode(promocode);
                                            }
                                        }} // function triggers when the user presses the enter key
                                        value={promocode}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography component={'img'} src={promoAdornmentIcon} ></Typography> {/*displays the validation icon*/}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <StyledOutlinedButton
                                        size='small'
                                        onClick={()=>{
                                            setFocus(false);
                                            setSendData({
                                            ...sendData,
                                            promotion_code:promocode
                                        });
                                        applyPromoCode(promocode);
                                        }}
                                        disabled={loading || promocode === "" } // button is disabled if there is no value in the promocode variable
                                    >Apply</StyledOutlinedButton>
                                    <StyledOutlinedButton size='small' onClick={removePromoCode} disabled={loading}>Remove</StyledOutlinedButton>
                                </Stack>
                                <Stack direction={"row"} spacing={1}>
                                {promoError && <Typography variant={promoVariant}>{promoError}</Typography>}
                                </Stack>
                            </StyledCostCard>
                            <StyledMonthlyCostCard mb={2}>
                                <Typography mb={2} variant='h3' color={'text.secondary'}>Recuring Monthly Cost</Typography>
                                <Stack direction={'column'} rowGap={1.5}>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography fontSize={'1.25rem'} fontWeight={700} >Monthly Rent:</Typography>
                                        <Typography fontSize={'1.25rem'} fontWeight={700} >${resDat.cost?.monthly.toFixed(2) || ''}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography>Monthly Protection Plan:</Typography>
                                        <Typography>${Number(protectionValue*sendData.number_of_boxes).toFixed(2)}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} flexWrap={false}>
                                        <Typography fontWeight={600}>Total</Typography>
                                        <Typography fontWeight={600}>${(
                                            (parseFloat(resDat.cost?.monthly || '0') + parseFloat(protectionValue*sendData.number_of_boxes || '0'))
                                            .toFixed(2)
                                        )}</Typography>
                                    </Stack>

                                </Stack>
                            </StyledMonthlyCostCard>
                            {/* Accordian */}
                            {/* <AccordianFaq/> */}
                        </Grid>
                    </Grid>
                    <Stack mt={4} direction={'row'} spacing={2} justifyContent={'space-between'} useFlexGap flexWrap={'wrap'}>
                        <StyledButton variant='contained' disableElevation onClick={handleBack} disabled={isRedirected}>Back</StyledButton>
                        <StyledButton
                        variant='contained'
                        disableElevation
                        onClick={handleContinue}
                        disabled={loading || disabled} // Disable if loading or any other condition
                        >
                        {loading ? <CircularProgress size={24} /> : 'Get Quote'}
                        </StyledButton>
                                        </Stack>
                                    </Container >
                                </Box >
        </>
  );
}

export default RealTimePrice
