import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Box, Typography} from '@mui/material';

const RedirectLoading = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography
            mb={{ xs: 1, sm: 2 }}
            variant="h1"
            color={'primary.dark'}
            textAlign={{ xs: 'center' }}
          >
            Redirecting to payments page...
          </Typography>
      <CircularProgress />
    </Box>
  );
};

export default RedirectLoading;