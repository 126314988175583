import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Success from '../../assets/images/success.svg';
import { StyledButton } from '../../components/styled-components/button';

function FinalSuccess({ handleOrderComplete }) {

    const payment_link = process.env.REACT_APP_PAYMENT_URL
    const key_name = process.env.REACT_APP_PAYMENT_API_KEY_NAME
    const key_value = process.env.REACT_APP_PAYMENT_API_KEY_VALUE

    const effectExecutedRef = useRef(false); // keeps track of the useEffect execution
    const [mail, setMail] = useState(""); // mail state
    const [orderId, setOrderId] = useState(""); // orderid state
    const [customerOrderId, setCustomerOrderId] = useState("");
    const order_response = JSON.parse(sessionStorage.getItem('order_response')) // fetches the order response object from the session storage which is set in previous step

    const regDat = JSON.parse(sessionStorage.getItem('registration_data'));
    const ronDat = JSON.parse(sessionStorage.getItem('s3data'));
    const calcRes = JSON.parse(sessionStorage.getItem('calculation_response'));
    const rtpDat = JSON.parse(sessionStorage.getItem('realtime_price_data'));
    const calcDat = JSON.parse(sessionStorage.getItem('calcData'))


    useEffect(() => {
        if (effectExecutedRef.current) return;
        effectExecutedRef.current = true; // setting this value to true will stop the useEffect from executing more than one time
        handleOrderComplete(true); // sets the order complete state to true which is present in the MainContent component
        window.scrollTo(0, 0); // scrolls to the top of the page when loaded for the first time

        // sets the states with appropriate values
        setMail(sessionStorage.getItem('mail'));
        setOrderId(order_response.order_id);
        setCustomerOrderId(order_response.customer_order_id);
        // eslint-disable-next-line
    }, []);

    const makePayment = () => {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = payment_link;

        const params = {
            'zip_code': regDat.zip_code,
            'delivery_date': ronDat.delivery_date,
            'number_of_boxes': rtpDat.number_of_boxes,
            'number_of_months_needed': rtpDat.number_of_months_needed,
            'delivery_time': ronDat.delivery_time,
            'storage_location': ronDat.location,
            'pickup_date': ronDat.pickup_date,
            'referral_source': '',
            'rental_rate': calcDat.rental_rate,
            'delivery_fee': (calcRes.cost.delivery === 0 ? "0.00" : calcRes.cost.delivery.toFixed(2)).toString(),
            'one_time_fee': (calcRes.cost.oneTimeFee === 0 ? "0.00" : calcRes.cost.oneTimeFee.toFixed(2)).toString(),
            'merchandise_fee': (calcRes.cost.merchandise === 0 ? "0.00" : calcRes.cost.merchandise.toFixed(2)).toString(),
            'tax': (calcRes.cost.tax === 0 ? "0.00" : calcRes.cost.tax.toFixed(2)).toString(),
            'total': (calcRes.cost.total === 0 ? "0.00" : calcRes.cost.total.toFixed(2)).toString(),
            'cost[tax]': (calcRes.cost.tax === 0 ? "0.00" : calcRes.cost.tax.toFixed(2)).toString(),
            'cost[total]': (calcRes.cost.total === 0 ? "0.00" : calcRes.cost.total.toFixed(2)).toString(),
            'cost[monthly]': (calcRes.cost.monthly === 0 ? "0.00" : calcRes.cost.monthly.toFixed(2)).toString(),
            'cost[delivery]': (calcRes.cost.delivery === 0 ? "0.00" : calcRes.cost.delivery.toFixed(2)).toString(),
            'cost[oneTimeFee]': (calcRes.cost.oneTimeFee === 0 ? "0.00" : calcRes.cost.oneTimeFee.toFixed(2)).toString(),
            'cost[merchandise]': (calcRes.cost.merchandise === 0 ? "0.00" : calcRes.cost.merchandise.toFixed(2)).toString(),
            'cost[final_total]': (calcRes.cost.final_total === 0 ? "0.00" : calcRes.cost.final_total.toFixed(2)).toString(),
            'proration[firstMonthStartDate]': calcRes.proration.firstMonthStartDate,
            'proration[firstMonthEndDate]': calcRes.proration.firstMonthEndDate,
            'proration[firstMonthRent]': (calcRes.proration.firstMonthRent === 0 ? "0.00" : calcRes.proration.firstMonthRent.toFixed(2)).toString(),
            'proration[nextMonthStartDate]': calcRes.proration.nextMonthStartDate,
            'proration[nextMonthEndDate]': calcRes.proration.nextMonthEndDate,
            'proration[nextMonthRent]': (calcRes.proration.nextMonthRent === 0 ? "0.00" : calcRes.proration.nextMonthRent.toFixed(2)).toString(),
            'proration[prorationStartDate]': calcRes.proration.prorationStartDate,
            'proration[prorationEndDate]': calcRes.proration.prorationEndDate,
            'proration[prorationRent]': (calcRes.proration.prorationRent === 0 ? "0.00" : calcRes.proration.prorationRent.toFixed(2)).toString(),
            'merchandise_list[9][quantity]': calcDat.padlock_quantity,
            'merchandise_list[9][value]': calcDat.padlock_price,
            'merchandise_list[27][quantity]':calcDat.protection_plan_quantity,
            'merchandise_list[27][value]':calcDat.protection_plan_price,
            'customer[first]': ronDat.first_name,
            'customer[last]': ronDat.last_name,
            'customer[phone][area]': ronDat.phone_number.slice(0, 3),
            'customer[phone][prefix]': ronDat.phone_number.slice(3, 6),
            'customer[phone][number]': ronDat.phone_number.slice(6),
            'customer[email]': ronDat.email,
            'customer[address]': ronDat.address1,
            'customer[city]': ronDat.city,
            'customer[state]': ronDat.state,
            'customer[zip]': ronDat.f_zip_code,
            'cid':customerOrderId,
            [key_name]: key_value // Add the key_name and key_value pair to the params
        };

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    };

    return (
        <>
            <Box py={{ xs: 5, md: 10 }}>
                <Container fixed>
                    <Box width={{ xs: '100%', md: '75%' }} margin={'auto'}>
                        <Box p={3} textAlign={'center'}>
                            <Typography mb={3} component={'img'} src={Success} />
                            <Typography mb={2} variant='h2' color={'text.secondary'}>Sweet Success!</Typography>
                            {/* <Typography mb={2} variant='h3' color={'text.secondary'}>Order ID: {orderId}</Typography> */}
                            <Typography mb={2} variant='h3' color={'text.secondary'}>Customer Order ID: {customerOrderId}</Typography>
                            <Typography mb={2} color={'text.secondary'} fontWeight={600}>You’ve successfully completed the process</Typography>
                        </Box>
                        <Box p={{ xs: 3, md: 4 }} bgcolor={'#EAF5FF'} borderRadius={'10px'}>
                            <Typography mb={{ xs: 2, md: 3 }} fontWeight={600} color={'common.black'}>WHAT NEXT?</Typography>
                            <Typography mb={3}>We’ve received your scheduling request, and a Big Box expert will be in touch with you within <Typography component={'span'} fontWeight={600}>2 business</Typography> hours to confirm your details. A copy of your request has been sent to your email <Typography component={'span'} fontWeight={600} sx={{ cursor: 'pointer' }}>  {mail} .</Typography></Typography>
                            <Box textAlign={'center'}>
                                <StyledButton variant='contained' disableElevation onClick={()=>{ makePayment();}}>Pay Now</StyledButton>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default FinalSuccess;
