// src/components/RouteGuard.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({ component: Component, orderComplete, isRedirected, label, ...rest }) => {
    // if the order is complete and if the user tries to load the screen 2 or screen 3, the user will be navigated to the screen 1
    if (orderComplete) {
        sessionStorage.clear();
        return <Navigate to="/" />;
    }
    // when the order is not complete, appropriate components will be rendered with respective props
    return <Component { ...rest } isRedirected={isRedirected} label={label} />
};

export default RouteGuard;
