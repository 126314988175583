import React from 'react'
import { Typography, Box, Container, Stack } from '@mui/material'
import Logo from '../logo/Logo'
import Contact from '../contact/Contact'
import ContactSm from '../contact/ContactSm'

function Header() {
    return (
        <>
            <Typography component={'header'} position={'sticky'} top={0} zIndex={1500} >
                <Box sx={{ backgroundColor: 'primary.main', minHeight: { xs: '26px', md: '40px' } }} >
                </Box>
                <Box py={{ xs: 3, sm: 4 }} sx={{ backgroundColor: 'common.white' }} boxShadow={1}>
                    <Container fixed>
                        <Box position={'relative'}>
                            <Box position={'absolute'} top={'-50px'}>
                                <a href='/' onClick={()=>{sessionStorage.clear()}}><Logo /></a>
                            </Box>
                            <Stack display={'flex'} direction='row' justifyContent={{xs:'center',md:'end'}} alignItems={'center'}>
                                <Contact />
                                <ContactSm  />
                            </Stack>
                        </Box>
                    </Container>
                </Box>
            </Typography >

        </>
    )
}

export default Header