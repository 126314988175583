import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from './components/styled-components/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/common-components/header/Header';
import Footer from './components/common-components/footer/Footer';
import MainContent from './components/common-components/MainContent';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Header />
      <MainContent />
      <Footer />
      <ToastContainer />
    </ThemeProvider>
    

  );
}

export default App;
