import {  createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#014D97',  //dark blue
            dark: '#034789',
            light: '#013E79'
        },
        secondary: {
            main: '#FF7100',  //orange
        },
        text: {
            primary: '#424242',
            secondary: '#1B344C',
            label: '#1B344C'
        },
        background: {
            default: '#FFF',
            paper: '#FFF',
            disabled: '#E9E9E978',
            grey1: '#F5F5F5',
            grey2: '#EEEEEE'
        }
    },
    typography: {
        fontSize: 16,
        // fontFamily:''
        // fontFamily: `'program', sans- serif`,
        fontFamily: `'Neue Haas Grotesk Display Pro', sans-serif`,
        body1: {
            fontFamily: `'Neue Haas Grotesk Display Pro', sans-serif`,
            fontSize: '1rem',
            fontWeight: '400'
        },
        label: {
            fontFamily: `'Neue Haas Grotesk Display Pro', sans-serif`,
            fontSize: '1rem',
            fontWeight: '500',
            display: 'inline-block',
            marginBottom: 4
        },
        label1: {
            fontFamily: `'Neue Haas Grotesk Display Pro', sans-serif`,
            fontSize: '1rem',
            fontWeight: '1000',
            display: 'inline-block',
            marginBottom: 4
        },
        question: {
            fontFamily: `'Neue Haas Grotesk Display Pro', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: '700',
            '@media screen and (max-width: 600px)': {
                fontSize: '1.125rem'
            }
        },
        h1: {
            fontFamily: `'program', sans- serif`,
            fontSize: '3rem',   //48px
            fontWeight: '700',
            '@media screen and (max-width: 600px)': {
                fontSize: '1.75rem'
            }
        },
        h2: {
            fontFamily: `'program', sans- serif`,
            fontSize: '2rem',   //32px
            fontWeight: '700',
            '@media screen and (max-width: 600px)': {
                fontSize: '1.75rem'
            }
        },
        h3: {
            fontFamily: `'program', sans- serif`,
            fontSize: '1.75rem',   //28px
            fontWeight: '700',
            marginBottom: '1rem',
            '@media screen and (max-width: 600px)': {
                fontSize: '1.75rem'
            }
        },
        h5: {
            fontFamily: `'program', sans- serif`,
            fontSize: '1.25rem',   //20px
            fontWeight: '700',
            marginBottom: '1rem',
            '@media screen and (max-width: 600px)': {
                // fontSize: '1.75rem'
            }
        },
        error:{

            fontFamily: `'program', sans- serif`,
            fontSize: '1rem',   //20px
            fontWeight: '300',
            fontStyle: 'italic',
            color:'red'
        },
        success:{
            fontFamily: `'program', sans- serif`,
            fontSize: '1rem',   //20px
            fontWeight: '300',
            fontStyle: 'italic',
            color:'green'
        }

    },
    shadows: ['none', ' 0px 4px 9px 0px #00000026', ' 0px 4px 4px 0px #0000001F', "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
   "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"],
    components: {
        MuiPopper: {
            styleOverrides: {
                root: {
                    '&.MuiPopper-root': {
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                    }
                }
            }
        },
        MuiTableCell:{
            styleOverrides:{
              root:{
                borderBottom:'none',
                padding:'4px'
              }
            }
          }
    },


});

export { customTheme };