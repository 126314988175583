import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import { Typography, Link, Stack} from '@mui/material';

function ContactSm() {
    return (
        <>
             <Link href="#" underline="none" color={'common.black'} sx={{display:{xs:'none',md:'block'}}} >
                <Stack direction={'row'} alignItems={'center'}  spacing={1.5} useFlexGap >                  
  
<PhoneIcon  sx={{fontSize:20}} />
                    <Typography color={'common.black'} sx={{':hover':{color:'secondary.main'}}} fontSize={18}  fontWeight={600}>619-436-5020</Typography>
                </Stack>
            </Link>
        </>

    )
}

export default ContactSm