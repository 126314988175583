// src/pages/not-allowed/NotAllowed.jsx
import React from 'react';
import { Box } from '@mui/material';

function NotAllowed() {
    sessionStorage.clear()
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <div>Not Allowed</div>
        </Box>
    );
}

export default NotAllowed;