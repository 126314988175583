// src/components/ReCaptchaButton.js

import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '@mui/material';
import { StyledButton } from '../../components/styled-components/button';
import { toast } from 'react-toastify';

function ReCaptchaButton({ onSubmit }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleClick = async () => {
        if (!executeRecaptcha) {
            toast.error("Recaptcha not available");
            return;
        }
        
        const token = await executeRecaptcha('registration');
        if (token) {
            onSubmit(token);
        } else {
            toast.error("Recaptcha failed");
        }
    };

    return (
        <StyledButton variant="contained" onClick={handleClick} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Start Your Quote'}
        </StyledButton>
    );
}

export default ReCaptchaButton;
