import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

function MonthlyQuote ({data}) {
    return (
        <>
        <Box
                sx={{
                    padding: '12px',
                    border: '1px solid #000000',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '6px',
                    marginTop: '24px',
                    marginBottom: '24px',
                }}
            >
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant='h5'>Item</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='h5' style={{textAlign: 'right'}}>Price</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='h5' style={{textAlign: 'center'}}>Qty</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='h5' style={{textAlign: 'right'}}>Total</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>Rent</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{textAlign: 'right'}}>${(data.monthlyRent/data.nofbox).toFixed(2)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{textAlign: 'center'}}>{data.nofbox}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{textAlign: 'right'}}>${data.monthlyRent}</Typography>
                            </TableCell>
                        </TableRow>
                        {data.monthlyProtectionPlan > 0 ?
                            <TableRow>
                                <TableCell>
                                    <Typography>Protection Plan</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{textAlign: 'right'}}>${(data.monthlyProtectionPlan / data.nofbox).toFixed(2)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{textAlign: 'center'}}> {data.nofbox}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{textAlign: 'right'}}>${data.monthlyProtectionPlan}</Typography>
                                </TableCell>
                            </TableRow> : <TableRow/>
                        }
                        <TableRow>
                            <TableCell
                                colSpan={3}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        textAlign: 'left',
                                        borderTop: '1px solid #E4E4E4',
                                        color: '#000000',
                                        padding: '16px 4px 4px 4px'
                                    }}
                                >
                                    Total - Monthly Recurring Costs
                                </Typography>
                            </TableCell>
                            <TableCell
                            >
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        textAlign: 'right',
                                        borderTop: '1px solid #E4E4E4',
                                        color: '#000000',
                                        padding: '16px 4px 4px 4px'
                                    }}
                                >
                                    ${(Number(data.monthlyProtectionPlan) + Number(data.monthlyRent)).toFixed(2)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </>
    )
}

export default MonthlyQuote;
