import React,{useState, useEffect} from 'react';
import AxiosInstance from '../../config/AxiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import CircularLoding from '../../components/common-components/CircularLoading';
import { toast } from 'react-toastify';

function QuoteRedirect({setIsRedirected}) {
    const { quote_id } = useParams();
    const navigate = useNavigate();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isBBInfoFetched, setIsBBInfoFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
  
    // useEffect to fetch the available states for the dropdown from the api
    useEffect(() => {
        // Function to fetch quote details and update session storage
    const fetchQuoteDetails = async () => {
        if (sessionStorage.getItem('quoteData') === null) {
          try {
            const response = await AxiosInstance.get('/bigbox-quote', { params: { "quote_id": quote_id } });
            const quoteDetails = response.data.quotes;
            if (quoteDetails) {
                const quoteDat = {
                    lead_id: quoteDetails.lead_id,
                    quote_id: quoteDetails.id,
                    userid: '10001',
                    protection_plan: quoteDetails.protection_plan,
                    zip_code: quoteDetails.zip_code,
                    delivery_date: quoteDetails.delivery_date,
                    number_of_boxes: quoteDetails.number_of_boxes,
                    number_of_months_needed: quoteDetails.number_of_months_needed,
                    delivery_time: quoteDetails.delivery_time,
                    storage_location: quoteDetails.storage_location,
                    pickup_date: quoteDetails.pickup_date,
                    referral_source: quoteDetails.referral_source,
                    rental_rate: Number(Number(quoteDetails.rental_rate).toFixed(2)),
                    delivery_fee: Number(quoteDetails.delivery_fee).toFixed(2),
                    one_time_fee: Number(Number(quoteDetails.one_time_fee).toFixed(2)),
                    merchandise_fee: Number(Number(quoteDetails.merchandise_fee).toFixed(2)),
                    padlock_quantity: quoteDetails.padlock_quantity,
                    padlock_price: Number(quoteDetails.padlock_price).toFixed(2),
                    protection_plan_quantity: quoteDetails.protection_plan_quantity,
                    protection_plan_price: quoteDetails.protection_plan_price,
                    tax: Number(Number(quoteDetails.tax).toFixed(2)),
                    total: Number(Number(quoteDetails.total).toFixed(2)),
                    customer: {
                      first: quoteDetails.customer_first_name,
                      last: quoteDetails.customer_last_name,
                      phone_number: quoteDetails.customer_phone_area + quoteDetails.customer_phone_prefix + quoteDetails.customer_phone_number,
                      phone: {
                        area: quoteDetails.customer_phone_area,
                        prefix: quoteDetails.customer_phone_prefix,
                        number: quoteDetails.customer_phone_number
                      },
                      email: quoteDetails.customer_email,
                      address: quoteDetails.customer_address,
                      address2: quoteDetails.customer_address2,
                      city: quoteDetails.customer_city,
                      state: quoteDetails.customer_state,
                      zip: quoteDetails.customer_zip
                    }
                  };
        
                  const registration_data = {
                    first_name: quoteDetails.customer_first_name,
                    last_name: quoteDetails.customer_last_name,
                    phone_number: quoteDetails.customer_phone_area + quoteDetails.customer_phone_prefix + quoteDetails.customer_phone_number,
                    email: quoteDetails.customer_email,
                    zip_code: quoteDetails.customer_zip
                  };
        
                  const quote_details = {
                    monthly_rent: Number(Number(quoteDetails.rental_rate).toFixed(2)),
                    mon_prot_plan: quoteDetails.protection_plan_price,
                    lock: Number(quoteDetails.padlock_price).toFixed(2),
                    in_de_fe: quoteDetails.delivery_fee,
                    in_de_di: quoteDetails.delivery_discount,
                    promo_disc: quoteDetails.promotion_code_value,
                    subTot: quoteDetails.total,
                    saleTax: quoteDetails.tax,
                    total: quoteDetails.total
                  };
        
                  const realtime_price_data = {
                    number_of_boxes: quoteDetails.number_of_boxes,
                    number_of_months_needed: (quoteDetails.number_of_months_needed).toString(),
                    promotion_code: quoteDetails.promotion_code,
                    promotion_code_value: quoteDetails.promotion_code_value,
                    number_of_padlocks: quoteDetails.padlock_quantity,
                    protection: (quoteDetails.protection_plan === 1) ? true : false
                  };
        
                  const calcData = {
                    zip_code: (quoteDetails.customer_zip).toString(),
                    delivery_date: quoteDetails.delivery_date,
                    number_of_boxes: (quoteDetails.number_of_boxes).toString(),
                    number_of_months_needed: (quoteDetails.number_of_months_needed).toString(),
                    promotion_code: quoteDetails.promotion_code === null ? '' : quoteDetails.promotion_code,
                    promotion_code_value: quoteDetails.promotion_code_value,
                    rental_rate: Number(quoteDetails.rental_rate).toFixed(2),
                    padlock_quantity: (quoteDetails.padlock_quantity).toString(),
                    padlock_price: Number(quoteDetails.padlock_price).toFixed(2),
                    protection_plan_quantity: (quoteDetails.protection_plan_quantity).toString(),
                    protection_plan_price: Number(quoteDetails.protection_plan_price).toFixed(2)
                  };


                  const s3dat = {
                    first_name: quoteDetails.customer_first_name,
                    last_name: quoteDetails.customer_last_name,
                    phone_number: quoteDetails.customer_phone_area + quoteDetails.customer_phone_prefix + quoteDetails.customer_phone_number,
                    email: quoteDetails.customer_email,
                    delivery_date: quoteDetails.delivery_date,
                    delivery_time: quoteDetails.delivery_time ? ((quoteDetails.delivery_time).toString()) : null,
                    pickup_date: quoteDetails.pickup_date,
                    location: quoteDetails.storage_location,
                    address1: quoteDetails.customer_address,
                    address2: quoteDetails.customer_address2,
                    city: quoteDetails.customer_city,
                    state: quoteDetails.customer_state,
                    f_zip_code: quoteDetails.customer_zip
                  };
        
                  const formatted_phone_number = `(${quoteDetails.customer_phone_area}) ${quoteDetails.customer_phone_prefix}-${quoteDetails.customer_phone_number}`;
                  const delcalzip = quoteDetails.customer_zip;
                  const lead_id = quoteDetails.lead_id;
                  const prospect_id = quoteDetails.bigbox_prospect_id;
                  const quote_id = quoteDetails.id;
        
                  sessionStorage.setItem('quoteData', JSON.stringify(quoteDat));
                  sessionStorage.setItem('registration_data', JSON.stringify(registration_data));
                  sessionStorage.setItem('quote_details', JSON.stringify(quote_details));
                  sessionStorage.setItem('realtime_price_data', JSON.stringify(realtime_price_data));
                  sessionStorage.setItem('calcData', JSON.stringify(calcData));
                  sessionStorage.setItem('s3data', JSON.stringify(s3dat));
                  sessionStorage.setItem('formatted_phone_number', formatted_phone_number);
                  sessionStorage.setItem('delcalzip', delcalzip);
                  sessionStorage.setItem('lead_id', lead_id);
                  sessionStorage.setItem('prospect_id', prospect_id);
                  sessionStorage.setItem('quote_id', quote_id);
                  sessionStorage.setItem('delInst', quoteDetails.delivery_instructions)
        
                  setIsDataFetched(true);
            }
          } catch (error) {
            toast.error('Error fetching quote details:');
          }
        } else {
          setIsDataFetched(true);
        }
      };
  
      // Function to fetch bigbox info and update session storage
    const fetchBigBoxInfo = async () => {
        if (sessionStorage.getItem('bigboxinfo') === null) {
          try {
            const response = await AxiosInstance.get("/bigbox-info");
            sessionStorage.setItem('bigboxinfo', JSON.stringify(response.data));
            setIsBBInfoFetched(true);
          } catch (error) {
            toast.error('Error fetching bigbox info:');
          }
        } else {
          setIsBBInfoFetched(true);
        }
      };

      // Execute both fetching functions
    fetchQuoteDetails();
    fetchBigBoxInfo();
    }, [quote_id]); // Run effect only when `quote_id` changes
  
    // Effect to handle navigation when both data fetching processes are done
  useEffect(() => {
    if (isDataFetched && isBBInfoFetched) {
      setIsLoading(false);
      setIsRedirected(true);
      navigate('/rent-online');
    }
    // eslint-disable-next-line
  }, [isDataFetched, isBBInfoFetched, navigate]);

  if(isLoading){
    return(
      <CircularLoding></CircularLoding>
    )
  }
  
    return null; // The component doesn’t render anything.
}

export default QuoteRedirect;