import axios from 'axios';

const AxiosInstance = axios.create({
    // sets the base url for all api calls in this application
    baseURL:process.env.REACT_APP_BIGBOX_GLOBAL_URL,
    // sets the api key for authentiation
    headers: {
        'x-api-key': process.env.REACT_APP_BIGBOX_API_KEY // Replace with your key-value pair
      }
});

export default AxiosInstance;